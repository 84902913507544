import request from '@/utils/request'

export default {
  getList(params) {
    return request({
      url: '/v1/cmsList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/cms/${id}`,
      method: 'get',
    })
  },
  update(data) {
    return request({
      url: '/v1/cmUpdate',
      method: 'post',
      data
    })
  },
  delete(id) {
    return request({
      url: `/v1/maintainDel/${id}`,
      method: 'get'
    })
  },
  startCm(data) {
    return request({
      url: '/v1/startCm',
      method: 'post',
      data
    })
  },
  toMonitor(data) {
    return request({
      url: '/v1/toMonitor',
      method: 'post',
      data
    })
  },
  toEngineer(data) {
    return request({
      url: '/v1/toEngineer',
      method: 'post',
      data
    })
  },
  engineerConfirm(data) {
    return request({
      url: '/v1/engineerConfirm',
      method: 'post',
      data
    })
  },
  applyConfirm(data) {
    return request({
      url: '/v1/applyConfirm',
      method: 'post',
      data
    })
  },
  appendParts(data) {
    return request({
      url: '/v1/workorderAppendParts',
      method: 'post',
      data
    })
  },
  removeParts(id) {
    return request({
      url: `/v1/workorderRemoveParts/${id}`,
      method: 'get'
    })
  },
  editParts(data) {
    return request({
      url: '/v1/workorderEditParts',
      method: 'post',
      data
    })
  },
  getInfo() {
    return request({
      url: '/v1/adminInfo',
      method: 'get'
    })
  },
  requestParts(data) {
    return request({
      url: `/v1/workorderRequestParts`,
      method: 'post',
      data
    })
  },
  out(id) {
    return request({
      url: `/v1/cmToOut/${id}`,
      method: 'get'
    })
  },
  voiceUpload(data) {
    return request({
      url: `/v1/cmsUploadVoice`,
      method: 'post',
      data
    })
  }
}
