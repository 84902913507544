<template>
  <div class="hx-detail hx-pro" >
    <van-nav-bar :title="title" left-text="返回" fixed left-arrow @click-left="goBack"  />
    <div style="padding-top: 44px"></div>
    <van-tabs v-if="editId" color="#1073e5" v-model="activeName" style="margin-bottom: 12px" @change="handleClick">
      <van-tab title="工单" name="1">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)">
        <van-form class="pm-content">
          <van-field v-model="data.coding" name="编码" label="编码:" readonly />
          <van-field v-model="data.equipment_name" name="名称" label="设备名称:" readonly />
          <van-field v-model="halt" is-link label="是否停机" arrow-direction="down" placeholder="选择是否停机" @click="showHaltPicker = true"/>
          <van-popup v-model:show="showHaltPicker" round position="bottom">
            <van-picker
                    :columns="haltColumns"
                    @cancel="showHaltPicker = false"
                    @confirm="haltConfirm"
            />
          </van-popup>
          <van-field v-model="error_code_id" is-link label="故障模式" arrow-direction="down" placeholder="选择故障模式" @click="showCodePicker = true"/>
          <van-popup v-model:show="showCodePicker" round position="bottom">
            <van-picker
                    :columns="codeColumns"
                    @cancel="showCodePicker = false"
                    @confirm="codeConfirm"
            />
          </van-popup>
          <van-field v-model="profession" is-link label="专业类型" arrow-direction="down" placeholder="选择专业类型" @click="showProfessionPicker = true"/>
          <van-popup v-model:show="showProfessionPicker" round position="bottom">
            <van-picker
                    :columns="professionColumns"
                    @cancel="showProfessionPicker = false"
                    @confirm="professionConfirm"
            />
          </van-popup>
          <van-field v-model="data.status" name="状态" label="状态:" readonly />
          <van-field v-model="data.workshop" name="车间" label="车间:" readonly />
          <van-field v-model="data.line" name="生产线" label="生产线:" readonly />
          <van-field v-model="data.process" name="工位" label="工位:" readonly />
          <van-field v-model="data.admin" name="负责人" label="负责人:" readonly />
          <van-field v-model="data.create_admin" name="报修人" label="报修人:" readonly />
          <van-field v-model="data.create_time" name="报修时间" label="报修时间:" readonly />
          <van-field v-model="data.from_type" name="来源类型" label="来源类型:" readonly />
          <van-field v-model="data.arrange_time" name="开始执行时间" label="开始执行时间:" readonly />
          <van-field v-model="data.admin" name="开始执行人" label="开始执行人:" readonly />
          <van-cell-group inset>
            <van-field
                    v-model="data.part"
                    rows="2"
                    autosize
                    label="维修部位"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入内容"
                    show-word-limit
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
                    v-model="data.question"
                    rows="2"
                    autosize
                    label="问题描述"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入内容"
                    show-word-limit
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
                    v-model="data.measure"
                    rows="2"
                    autosize
                    label="维护措施"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入内容"
                    show-word-limit
            />
          </van-cell-group>
        </van-form>
        <van-button v-if="data.status === '待维修' && cmCanBegin === 2" class="btn-s" block round type="success" @click="start">开始执行</van-button>
        <van-button v-if="data.status === '维修中' && cmCanBegin === 2" block round type="primary" class="btn-s" @click="monitor">请求班长审核</van-button>
        <van-button v-if="data.status === '待班长审核'&& cmCanMonitor === 2" class="btn-s" block round type="primary" @click="engineer(2)">请求工程师审核</van-button>
        <van-button v-if="data.status === '待班长审核'&& cmCanMonitor === 2" class="btn-s" block round type="danger" @click="engineer(1)">驳回</van-button>
        <van-button v-if="data.status === '待工程师审核'&& cmCanEngineer === 2" class="btn-s" block round type="primary" @click="engineerSubmit(2)">工程师审核</van-button>
        <van-button v-if="data.status === '待工程师审核'&& cmCanEngineer === 2" class="btn-s" block round type="danger" @click="engineerSubmit(1)">驳回</van-button>
        <van-button v-if="data.status === '待确认'&& cmCanConfirm === 2" class="btn-s" block round type="success" @click="cmConfirm">现场人员确认</van-button>
        <div class="air"></div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="备件" name="2">
        <van-form class="pm-content" >
          <van-list>
            <van-cell>
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="20">备件名称</van-col>
                  <van-col span="4"><p style="color: #666666">数量</p></van-col>
                </van-row>
                <van-row type="flex">
                  <van-col span="12"><p style="font-size: 12px;color: #666666">型号</p></van-col>
                </van-row>
              </van-swipe-cell>
            </van-cell>
            <van-cell v-for="item in other_parts" :key="item.id" style="margin-top: 4px">
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="21">{{ item.parts.name }}</van-col>
                  <van-col span="3">{{ item.parts.number }}</van-col>
                </van-row>
                <van-row type="flex">
                  <van-col span="12"><p>{{ item.coding }}</p></van-col>
                </van-row>
                <template #right>
                  <van-button square text="删除" size="mini" type="danger" @click="partsRemove(item.id)" />
                </template>
              </van-swipe-cell>
            </van-cell>
          </van-list>
        </van-form>
       <div v-if="data.status === '维修中'">
         <div class="pm-content">
           <van-field v-model="parts" is-link readonly label="备件" placeholder="选择备件" arrow-direction="down" @click="chooseParts"/>
           <van-popup :show="showPartsPicker" round position="bottom">
             <van-search v-model="partsVal" placeholder="搜索备件" />
             <van-picker
                 :columns="partsColumns"
                 @cancel="showPartsPicker = false"
                 @confirm="partsConfirm"
             />
           </van-popup>
         </div>
         <div class="parts-content">
           <p>当前数量：{{ partsNumber }}</p>
           <p>备件编码：{{ partsCoding }}</p>
           <p>所在库房：{{ partsStore }}</p>
           <p>备件型号：{{ partsSpec }}</p>
         </div>
         <div class="pm-content">
           <van-field v-model="number" type="digit" name="备件数量" label="需要数量:" />
         </div>
         <van-button style="width: 240px; margin: 10px auto" block round type="primary" @click="partsAdd()">添加备件</van-button>
         <van-button style="width: 240px; margin: 10px auto" block round type="success" @click="requestParts">请求备件</van-button>
       </div>
      </van-tab>
      <van-tab title="媒体" name="3">
        <div class="media">
          <div class="media-btn">
            <van-icon name="photo-o" color="#1989fa" size="20" @click="getPhoto" /><span>拍照</span>
          </div>
          <div class="photo">
            <div class="photo_item"><img src="../../assets/cq1.jpeg" alt=""></div>
            <div class="photo_item"><img src="../../assets/cq2.jpg" alt=""></div>
            <div></div>
            <div></div>
          </div>
          <div class="record">
            <div class="media-btn">
              <van-icon name="play-circle-o" color="#1989fa" size="20" @click="startRecord" /><span>录音</span>
            </div>
            <div class="media-btn">
              <van-icon name="stop-circle-o" color="#1989fa" size="20" @click="endRecord" /><span>停止</span>
            </div>
            <div class="media-btn">
              <van-icon name="add-o" color="#1989fa" size="20" @click="saveRecord" /><span>保存</span>
            </div>
          </div>
          <div class="media_table">
            <div class="media_list" v-for="(item) in data.voice_list" :key="item.id">
              <div class="media_item">
                {{ item.create_time }}
              </div>
              <div style="color: #2299dd" @click="playRecord(item.media_id)">播放</div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import API from '@/api/workorder/cm'
import PARTS from '@/api/stock/parts'
import wx from 'weixin-js-sdk'
import wxApi from '@/api/weixin/weixin'
import Cookies from 'js-cookie'
export default {
  inject: ['goUrl', 'isEdit', 'add', 'update'],
  data() {
    return {
      title: '',
      data: {
        voice_list: [],
      },
      parts: '',
      activeNames: ['1'],
      editId: null,
      name: '',
      reading: true,
      halt: '',
      profession: '',
      error_code_id: '',
      equipment: null,
      admin_id: null,
      parts_id: null,
      start_time: null,
      isLoading: false,
      end_time: null,
      partsNumber: null,
      partsCoding: null,
      partsStore: null,
      partsSpec: null,
      activeName: 1,
      other_task: [],
      cmCanBegin: null,
      cmCanMonitor: null,
      cmCanEngineer: null,
      cmCanConfirm: null,
      partsColumns: [],
      type: 1,
      partsVal: '',
      number: null,
      memberSearch: [],
      partsSearch: [],
      haltColumns: [
        {id: 1 ,text: '是'},
        {id: 2 ,text: '否'}
      ],
      codeColumns: [
        { id: 1, text: '其它故障' },
        { id: 2, text: '气路故障' },
        { id: 3, text: '润滑故障' },
        { id: 4, text: '液压故障' },
        { id: 5, text: '电气故障' },
        { id: 6, text: '机械故障' }
      ],
      professionColumns: [
        { id: 1, text: '电气' },
        { id: 2, text: '机械' },
        { id: 3, text: '所有' }
      ],
      startDate: new Date(),
      endDate: new Date(),
      showHaltPicker: false,
      showCodePicker: false,
      showPartsPicker: false,
      showProfessionPicker: false,
      endTimePicker: false,
      completeTimePicker: false,
      arrangeTimePicker: false,
      finnshWeixin: false,
      serverId: null,
      backId: null,
      localId: '',
      imageBase64: ''
    }
  },
  created() {
    const edit = this.isEdit()
    if (edit) {
      this.title = '故障维修详情'
      this.editId = edit - 0
      this.getToken()
      this.info()
    } else {
      this.title = '故障维修'
    }
  },
  methods: {
    async info() {
      this.getWxConfig()
      const res = await API.getOne(this.editId)
      this.data = res.data
      this.equipment = res.data.equipment
      this.other_task = res.data.other_tasks
      this.other_member = res.data.other_member
      this.other_time = res.data.other_time
      this.other_parts = res.data.parts_lists
      this.haltColumns.forEach(item => {
        if(item.id === res.data.halt){
          this.halt = item.text
        }
      })
      this.codeColumns.forEach(item => {
        if(item.id === res.data.error_code_id){
          this.error_code_id = item.text
        }
      })
      this.professionColumns.forEach(item => {
        if(item.id === res.data.profession){
          this.profession = item.text
        }
      })
      const arr = Cookies.get('pmsKey')
      let flag = false
      if(arr) {
          flag = arr.includes(this.editId)
      }
      if(flag) {
        this.reading = false
      } else {
        console.log('无扫码记录')
      }
    },
    getWxConfig() {
      const url = (window.location.href).split('#')[0]
      const _this = this
      wxApi.wxJSTicket({ url }).then(res =>{
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名
          jsApiList: ['chooseImage', 'getLocalImgData', 'startRecord', 'stopRecord', 'playVoice', 'uploadVoice', 'downloadVoice'] // 必填，需要使用的 JS 接口列表
        })
        wx.ready(function(){
          _this.finnshWeixin = true
        })
      })
    },
    getPhoto() {
      const _this= this
      console.log('点击1')
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          const localIds = res.localIds
          console.log(localIds)
          wx.getLocalImgData({
            localId: localIds[0].toString(),
            success: function (res) {
              let localData = res.localData
              if (localData.indexOf('data:image') == 0) {
                _this.imageBase64 = localData;
              } else {
                _this.imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '')
              }
              console.log(_this.imageBase64)
            }
          });
        }
      })
    },
    startRecord() {
      wx.startRecord()
    },
    playRecord(id) {
      console.log('播放了！！')
      wx.playVoice({
        localId: id // 需要播放的音频的本地ID，由stopRecord接口获得
      })
    },
    endRecord() {
      const _this = this
      wx.stopRecord({
        success: function (res) {
          _this.localId = res.localId;
          wx.uploadVoice({
            localId: _this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
              _this.serverId = res.serverId; // 返回音频的服务器端ID
            }
          });
        }
      })
    },
    saveRecord() {
      const data = {
        workorder_id: this.editId,
        media_id: this.localId
      }
      API.voiceUpload(data).then(res => {
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast('保存成功')
          this.info()
        }
      })
    },
    base64ToFile(base64, fileName) {
      // base64转file文件
      let arr = base64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
    goBack() {
      this.goUrl('/workorder/cm')
    },
    handleClick(name) {
      this.type = parseInt(name)
    },
    onRefresh(activeName) {
      this.info()
      this.activeName = activeName
      setTimeout(() => {
        this.isLoading = false;
      }, 1000)
    },
    haltConfirm(halt) {
      this.data.halt = halt.id
      this.halt = halt.text
      this.showHaltPicker = false
    },
    codeConfirm(code){
      this.data.error_code_id = code.id
      this.error_code_id = code.text
      this.showCodePicker = false
    },
    professionConfirm(profession){
      this.data.profession = profession.id
      this.profession = profession.text
      this.showProfessionPicker = false
    },
    partsRemove(id) {
      API.removeParts(id).then(res => {
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast('删除成功')
        }
      }).finally(() => {
        this.info()
      })
    },
    chooseParts() {
      PARTS.partsList({limit:50}).then(res => {
        this.partsList = res.data.data
        let arr = this.partsList.map((item) => {
          return Object.assign({}, { id: item.id, text: item.name, coding: item.coding, number: item.number, store: item.store, spec: item.spec })
        })
        this.partsColumns = arr
        this.partsSearch = arr
      })
      this.showPartsPicker = true
    },
    partsConfirm(parts) {
      this.partsCoding = parts.coding
      this.partsStore = parts.store
      this.parts_id = parts.id
      this.partsSpec = parts.spec
      this.parts = parts.text
      this.partsNumber = parts.number
      this.showPartsPicker = false
      this.partsVal = ''
    },
    partsAdd() {
      const partsData = {
        workorder_id: this.editId,
        parts_id: this.parts_id,
        number: this.number,
        remark: this.remark
      }
      API.appendParts(partsData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('新增成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    requestParts() {
      API.requestParts({ id: this.editId }).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('请求成功，请去库房领取')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    start() {
      const startData = {
        id: this.editId,
        profession: this.data.profession,
        error_code_id: this.data.error_code_id,
        halt: this.data.halt,
        part: this.data.part,
        question: this.data.question,
        measure: this.data.measure
      }
      API.startCm(startData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('操作成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    monitor() {
      const monitorData = {
        id: this.editId,
        question: this.data.question,
        measure: this.data.measure
      }
      API.toMonitor(monitorData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('操作成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    engineer(status) {
      const data = {
        status: status,
        id: this.editId,
        question: this.data.question,
        measure: this.data.measure
      }
      API.toEngineer(data).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('操作成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    engineerSubmit(status) {
      const data = {
        status: status,
        id: this.editId,
        question: this.data.question,
        measure: this.data.measure
      }
      API.engineerConfirm(data).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('操作成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    cmConfirm() {
      const data = {
        id: this.editId,
        question: this.data.question,
        measure: this.data.measure
      }
      API.applyConfirm(data).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('操作完成')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    getToken() {
      this.token = Cookies.get('Admin-Token')
      this.getRole()
    },
    getRole() {
      API.getInfo({ token: this.token }).then(res => {
        this.cmCanBegin = res.data.cmCanBegin
        this.cmCanMonitor = res.data.cmCanMonitor
        this.cmCanEngineer = res.data.cmCanEngineer
        this.cmCanConfirm = res.data.cmCanConfirm
        console.log(this.cmCanBegin, this.cmCanMonitor, this.cmCanEngineer, this.cmCanConfirm)
      })
    }
  },
  watch: {
    // 搜索人员
    memberVal: {
      handler() {
        if (this.memberVal == '') {
          this.memberColumns = this.memberSearch
        } else {
          this.memberColumns = []
          this.memberSearch.forEach(item => {
            if (item.text.indexOf(this.memberVal) > -1) {
              this.memberColumns.push(item)
            }
          })
        }
      },
      immediate: true
    },
    partsVal: {
      handler() {
        if (this.partsVal == '') {
          this.partsColumns = this.partsSearch
        } else {
          this.partsColumns = []
          PARTS.partsList({name: this.partsVal}).then(res =>{
            this.partsList = res.data.data
            let arr = this.partsList.map((item) => {
              return Object.assign({}, { id: item.id, text: item.name, coding: item.coding, number: item.number, store: item.store, spec: item.spec })
            })
            this.partsColumns = arr
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.air{
  height: 20px;
  width: 100%;
  margin-bottom: 40px;
}
.media-btn{
  width: 20%;
  margin-top: 12px;
  margin-left: 10px;
  display: flex;
  padding:10px;
  border: 1px solid #2299dd;
}
.media{
  background-color: #fff;
  width: 97%;
  margin: 10px auto;
  overflow: hidden;
  min-height: 80vh;
}
.record{
  display: flex;
  align-items: center;
}
.media_list{
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0;
}
.media_list:nth-child(odd){
  background-color: #f2f2f2;
}
.media_list:nth-child(even){
  background-color: #fff;
}
.media_table{
  margin-top: 8px;
}
.photo{
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.photo_item{
  width: 40%;
  margin-top: 10px;
}
.photo_item img{
  width: 100%;
  height: auto;
}
</style>
